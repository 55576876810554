import * as React from "react"
import useStoryblok from "../lib/storyblok"
import { useContext, useEffect } from "react"

import { graphql, useStaticQuery, Link } from 'gatsby'

import Seo from "../components/services/seo"
import DynamicComponent from "../components/services/dynamicComponent"
import "../styles/tag.less"
import { CurrentPageContext, DataContext } from "../context"

const Tag = ({ pageContext, location }) => {
  let story = pageContext.story
  story = useStoryblok(story, location)

  const { currentPage, setCurrentPage } = useContext(CurrentPageContext);

  useEffect(() => {
    setCurrentPage([{
      slug: '',
      name: story.name
    }])
  }, [setCurrentPage, story.name])


  const allStories = useStaticQuery(graphql`
    {
      items: allStoryblokEntry(
        sort: {order: ASC, fields: published_at}
      ) {
        edges {
          node {
            id
            uuid
            name
            slug
            full_slug
            content
            created_at
          }
        }
      }
    }
  `)
  let journalStories = [];
  let portfolioStories = [];

  if (allStories.items) {
    allStories.items.edges.map((p, i) => {
      const content = p.node.content
      const newContent = typeof content === 'string' ? JSON.parse(content) : content
      p.node.content = newContent
      if (p.node.full_slug.includes("journal-posts")) {
        journalStories.push(p.node);
      }
      if (p.node.full_slug.includes("portfolioworks")) {
        portfolioStories.push(p.node);
      }
      return p.node
    })
  }

  journalStories = journalStories.filter(j => (j.content.tags && j.content.tags.includes(story.uuid)));
  portfolioStories = portfolioStories.filter(j => (j.content.tags && j.content.tags.includes(story.uuid)));

  let components = [];
  if (story.content.body) {
    components = story.content.body.map(blok => {
      return (<DynamicComponent blok={blok} key={blok._uid} />)
    })
  }

  const metaTitle = (story.content.title_meta) ? story.content.title_meta : ('BlackAndMilk | ' + story.content.name);
  const metaDescription = (story.content.description_meta) ? story.content.description_meta : (story.content.name + ' - Black and Milk - an award wining interior design studio in London, UK. Detail driven residential projects for private clients.');

  return (
    <>
      <Seo title={metaTitle} description={metaDescription} url={location.href} />
      <DataContext.Provider value={{
        data: {
          journalStories: journalStories,
          portfolioStories: portfolioStories
        }
      }}>
        {components}
      </DataContext.Provider>
    </>
  )
}

export default Tag
